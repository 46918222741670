import "./polyfills";

function getTargets(el) {
	return Array.from(document.querySelectorAll(el));
}

function pageHasTargets(targetArr) {
	if (targetArr.length > 0) {
		return true;
	}
	return;
}

const addEventListener = function (targetArr, eventFuntion) {
	targetArr.forEach((n) => {
		n.addEventListener("click", eventFuntion);
	});
};

function addMovie(tar) {
	const clickedURL = tar.getAttribute("data-url");
	const moviePlayer = document.querySelector(".Movie-gal .Movie-gal-player");
	moviePlayer.innerHTML = clickedURL;
}

function toggleMovie(event) {
	const activeMovie = document.querySelector(".Active-movie");
	if (activeMovie) {
		activeMovie.classList.remove("Active-movie");
	}
	const movieData = event.target;
	movieData.classList.add("Active-movie");
	addMovie(movieData);
}

function loadFirst(movieArr) {
	const firstMovie = movieArr[0];
	addMovie(firstMovie);
	firstMovie.classList.add("Active-movie");
}

function movieSwap() {
	const movies = getTargets(".Movie-gal .Movie-gal-img");
	if (pageHasTargets(movies)) {
		addEventListener(movies, toggleMovie);
		loadFirst(movies);
	}
}

function modalOpen() {
	const clickedModal = this;
	const getModalID = clickedModal.getAttribute("data-modal");
	const modalToOpen = document.querySelector(
		`.Modal--wrap[data-modal="${getModalID}"]`
	);
	const getBody = document.querySelector("body");

	modalToOpen.classList.add("Open-modal");
	getBody.classList.add("Open-modal");
}

function modalClose() {
	const openModal = document.querySelector("div.Open-modal");
	const getBody = document.querySelector("body");
	openModal.classList.remove("Open-modal");
	getBody.classList.remove("Open-modal");
}

function modalClick() {
	const getModals = getTargets(".Card");
	const getCloseButton = getTargets(".Modal__close");
	if (pageHasTargets(getModals)) {
		addEventListener(getModals, modalOpen);
		addEventListener(getCloseButton, modalClose);
	}
}

document.addEventListener("DOMContentLoaded", function () {
	movieSwap();
	modalClick();
});
